import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

const UTILS_API_URL = environment.apiUrl+'utils/';
const BASE_PLANO_URL = environment.basePlanoUrl;
const EXP_BASE_URL = environment.baseUrlV2;
const EXPURL = environment.expurl;
const USERURL = environment.userUrl;
const ACCESS_TOKEN_EXPIRY_DURATION = 5;
const USERS_V3_URL = environment.userUrlV3;
const GO_PROCESS_URL = environment.goProcess;

const apiData = {
  user: {
    me: `${USERURL}me`,
    meV3: `${USERS_V3_URL}users/me`,
    login: `${USERURL}login`,
    users: `${USERURL}users/`,
    acceptTerms: `${USERURL}/user/termscondition`,
    usersByID: `${EXP_BASE_URL}/users/`,
    getUser: `${USERURL}user/search`,
    searchUserV3: `${USERS_V3_URL}users/search`,
    logout: `${USERURL}logout`,
    refreshToken: `${USERURL}refresh_access_token`,
    newsletter: `${USERURL}newsletter`,
    approval: `${USERURL}user/approval`,
    approvalV3: `${USERS_V3_URL}users/onboard`,
    sharedWith: `${USERURL}bulk/users/detail`,
    countriesList: `${USERS_V3_URL}countries`,
    countriesListWithZone: `${USERS_V3_URL}countries/zone/list`,
  },
  pdfUtility: {
    getPlansList: (expId: string) =>
      `${BASE_PLANO_URL}api/v1/${expId}/plans`,
    getTemplateList: () =>
      `${UTILS_API_URL}api/v2/pdf/template`,
    getCountryList: () =>
      `${USERS_V3_URL}countries`,
    getKeyAccountList: () =>
      `${USERS_V3_URL}key-accounts`,
    download: `${EXPURL}download`,
    generatePDF: () =>
      `${UTILS_API_URL}api/v2/generate/pdf`,
    uploadFile: (templateId: string) =>
      `${UTILS_API_URL}api/v2/${templateId}/upload/excel`,
    goProcessStatusCall: (taskId: string) =>
      `${GO_PROCESS_URL}${taskId}/status`
  }
};

@Injectable({
  providedIn: "root",
})
export class StateService {
  calledApiList: any = [];
  apiList = apiData;

  constructor(
  ) {
  }

  addCalledApi(url: string) {
    setTimeout(() => {
      this.calledApiList.push(url);
    }, 0);
  }

  removeCalledApi(url: string) {
    setTimeout(() => {
      this.calledApiList.splice(this.calledApiList.indexOf(url), 1);
    }, 0);
  }

  popOutCalledApi() {
    setTimeout(() => {
      this.calledApiList.pop();
    }, 0);
  }

  setTokenExpiryDate() {
    window.localStorage.removeItem("tokenExpiryDate");

    let tokenExpiryDuration: number = ACCESS_TOKEN_EXPIRY_DURATION;
    let currentDate = new Date();
    let tokenExpiryDate = new Date(currentDate.getTime());

    tokenExpiryDate.setMinutes(currentDate.getMinutes() + tokenExpiryDuration);
    window.localStorage.setItem("tokenExpiryDate", tokenExpiryDate.toString());
  }

  getTokenExpiryDate() {
    return new Date(Date.parse(window.localStorage['tokenExpiryDate']));
  }

  getToken() {
    return window.localStorage['c'];
  }

  getRefreshToken(): string | null {
    return window.sessionStorage['d'];
  }

  removeQueryParamsFromUrl(url: string): string {
    return url.split("?")[0]; // Remove query params
  }

  groupByKey(array: any, key: any) {
    return array.reduce((hash: any, obj: any) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }
}
