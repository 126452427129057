import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { replaceSkuObjectProps, skuObjectProps } from '../constants/interfaces';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private url: string = environment.api;

  constructor(private http: HttpClient) {}

  private handleError(error: any): Observable<never> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${
        error.message
      }\nResponse: ${JSON.stringify(error.error)}`;
    }
    console.log(errorMessage);
    return throwError(() => new Error(errorMessage));
  }

  removeSkuId(skuIds: string): Observable<any> {
    return this.http
      .delete<any>(`${this.url}/delete`, {
        body: {
          id: skuIds,
        },
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((error) => {
          console.error('Error occurred:', error);
          return of(null);
        })
      );
  }

  uploadZip(file: File): Observable<any> {
    console.log('Hi1');
    const formData: FormData = new FormData();
    console.log(file);
    formData.append('file', file, file.name);

    return this.http
      .post<any>(`${this.url}/upload-zip`, formData)
      .pipe(catchError(this.handleError));
  }

  addSkuId(skuObjectArray: skuObjectProps[]): Observable<any> {
    return this.http
      .post<any>(`${this.url}/add`, skuObjectArray, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((error) => {
          console.error('Error occurred:', error);
          return of(null);
        })
      );
  }

  addSkuWithExcel(excel: File): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('file', excel, excel.name);
    return this.http
      .post<any>(`${this.url}/upload-excel/add`, formData, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((error) => {
          console.error('Error occurred:', error);
          return of(null);
        })
      );
  }

  replaceSkuId(skuObjectArray: replaceSkuObjectProps[]): Observable<any> {
    return this.http
      .post<any>(`${this.url}/replace`, skuObjectArray, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((error) => {
          console.error('Error occurred:', error);
          return of(null);
        })
      );
  }

  replaceSkuWithExcel(excel: File): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('file', excel, excel.name);
    return this.http
      .post<any>(`${this.url}/upload-excel/replace`, formData, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((error) => {
          console.error('Error occurred:', error);
          return of(null);
        })
      );
  }
}
