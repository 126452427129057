import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ApiService } from '../services/api.service';
import { AccordionModule } from 'primeng/accordion';
import { InputNumberModule } from 'primeng/inputnumber';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { MenubarModule } from 'primeng/menubar';
import { MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RouterModule } from '@angular/router';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    RouterModule,
    MenubarModule,
    TooltipModule,
    ProgressSpinnerModule,
    InputNumberModule,
    CardModule,
    DropdownModule,
    FileUploadModule,
    InputTextModule,
    ButtonModule,
    AccordionModule,
    ToastModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    RouterModule,
    MenubarModule,
    TooltipModule,
    ProgressSpinnerModule,
    InputNumberModule,
    CardModule,
    DropdownModule,
    FileUploadModule,
    InputTextModule,
    ButtonModule,
    AccordionModule,
    ToastModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
  ],
  providers: [ApiService, MessageService],
})
export class SharedModule {}
