import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEventType } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(private http: HttpClient) {}

  getMethod(url: string): Observable<any> {
    return this.http.get(url);
  }

  postMethod(url: string, data?: any): Observable<any> {
    return this.http.post(url, data);
  }

  putMethod(url: string, data?: any): Observable<any> {
    return this.http.put(url, data);
  }

  patchMethod(url: string, data?: any): Observable<any> {
    return this.http.patch(url, data);
  }

  deleteMethod(url: string, data?: any): Observable<any> {
    return this.http.delete(url, { body: data });
  }

  postFileMethod(url: string, data: any): Observable<any> {
    let headers = new HttpHeaders();
    return this.http
      .post(url, data, {
        headers,
        observe: "events",
        reportProgress: true,
      })
      .pipe(
        map((event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            return { status: "progress", percent: percentDone };
          }
          if (event.type === HttpEventType.Response) {
            return event.body;
          }
        })
      );
  }

  getBlobMethod(url: string): Observable<any> {
    let headers = new HttpHeaders();
    return this.http.get(url, { headers: headers, responseType: "blob" });
  }

  getExcelMethod(url: string): Observable<any> {
    let headers = new HttpHeaders();
    return this.http.get(url, { headers: headers, responseType: "blob" });
  }
  
  getPDFMethod(url: string, data: any): Observable<any> {
    let headers = new HttpHeaders();
    return this.http.post(url, data, {
      headers: headers,
      responseType: "blob",
    });
  }

  getMockFile(url: string) {
    return this.http.get(url);
  }
}
